<div class="row" style="margin-bottom: 5px;">
    <div class="col-lg-2 col-md-3 col-xm-6 col-xs-12">
        <ix-button (click)="downloadMasterFile()">Download</ix-button>
    </div>
    
</div>
<div class="row" style="margin-bottom: 5px;">
    <div class="col-lg-2 col-md-3 col-xm-6 col-xs-12">
        <label class="btn btn-primary" style="margin: auto;">
            Select new Master File
            <span class="newton-search"></span>
            <input #fileSelection type="file"
            accept="application/msexcel"
            (change)="storeFile()">
        </label>
    </div>

    <div class="col-lg-2 col-md-3 col-xm-6 col-xs-12">
        <ix-button (click)="uploadMasterfile()" [disabled]="masterFileToUpload === undefined">Upload Masterfile</ix-button>  
    </div>
</div>