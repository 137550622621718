// This file is relevant for dev stage (build to Backend)


const baseUrl = '';

export const environment = {
  production: false,
  baseUrl: baseUrl,
  auth: {
    domain: 'siemens-qa-00169.eu.auth0.com',
    clientId: '79bpmnaaYGSNHhFV9MEaVwJA0EGx40jf',
    authorizationParams: {
      audience: 'excel-calculator-dev',
      redirect_uri: window.location.origin,
    },
    errorPath: '/error'
  },
  httpInterceptor: {
    allowedList: [`${baseUrl}*`],
  },
};  