import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.css']
})
export class ContractComponent implements OnInit {
  @Output()
  stepBack: EventEmitter<void> = new EventEmitter();

  @Input()
  contractForm!: FormGroup;

  constructor(private dataService: DataService){  
  }

  async ngOnInit(): Promise<void> {  
    await this.dataService.readData(this.contractForm);
  } 

  StepBack() {
    this.stepBack.emit();
  }
}
