
export class UIElements {
  public static dropDowns: { [key: string]: { value: string | number }[] } = {
    "Contractform": [
      { value: '8 / 5' },
      { value: '24 / 7' }
    ],
    "Remoteconnectionavailable": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "typeofpricing": [
      { value: '0' },
      { value: '1' },
      { value: '2' }
    ],


    "simplecalc": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "selection": [
      { value: 1 },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5 },
      { value: 6 },
    ],


    "FrontFirewallredundant": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "Back-endFirewallredundant": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "Coreswitchesredundant": [
      { value: 'Yes' },
      { value: 'No' }
    ],


    "ContinuousMonitoring": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "IncidentManagement": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "DisasterRecoverySupport": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "Reporting": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "NetworkManagement": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "DomainManagement": [
      { value: 'Yes' },
      { value: 'No' }
    ],

    "WindowsServerUpdateServices(WSUS)": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "WindowsServerUpdateServices(WSUS)Frq": [
      { value: 1 },
      { value: 2 },
      { value: 4 },
      { value: 12 }
    ],
    "Back-up&Restore": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "Backup&restoreVM'sFrq": [
      { value: 1 },
      { value: 2 },
      { value: 4 },
      { value: 12 }
    ],
    "Software&FirmwareUpdates": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "Firmwareupdates": [
      { value: 1 },
      { value: 2 },
      { value: 4 },
      { value: 12 }
    ],
    "PreventiveMaintenance": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "Preventive/periodicmaintenanceFrq": [
      { value: 1 },
      { value: 2 },
      { value: 4 },
      { value: 12 }
    ],
    "Configuration&ChangeManagement": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "AssetManagement": [
      { value: 'Yes' },
      { value: 'No' }
    ],

    "Securitypatching": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "TrafficMonitoring": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "VulnerabilityManagement": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "AnomalyDetection": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "SecurityInformation&EventManagement(SIEM)": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "CyberEmergencyResponseTeam(CERT)": [
      { value: 'Yes' },
      { value: 'No' }
    ],

    "RemoteSupport": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "ServiceDesk": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "ServiceRequestManagement": [
      { value: 'Yes' },
      { value: 'No' }
    ],
  }
}
