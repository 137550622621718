<ix-content-header
      slot="header"
      header-title="Projects">      
</ix-content-header>
<div *ngIf="!areProjectsLoading">
  <ix-button *ngIf="projects?.length" class="m-1" variant="primary" icon="add" (click)="addProject()">Add</ix-button>

  <table class="table table-striped" *ngIf="projects?.length">  
    <colgroup>
      <col class="columnNumber" />
      <col class="columnName" />
      <col class="columnActions" />
    </colgroup>
      <thead>  
        <tr>  
          <th scope="col">#</th>
          <th scope="col">Name</th>  
          <th scope="col">Actions</th>  
        </tr>  
      </thead>  
      <tbody>  
          <ng-container *ngFor="let project of projects; index as i">
              <tr> 
                  <th scope="row">{{i}}</th> 
                  <td>{{project.name}}</td>  
                  <td>
                      <ix-icon-button class="m-1" variant="primary" icon="start-data-analysis"
                        (click)="startCalculation(project.id)"></ix-icon-button>
                      <ix-icon-button class="m-1" icon="pen" variant="secondary" (click)="editProject(project)"></ix-icon-button>
                      <ix-icon-button class="m-1" icon="trashcan" variant="secondary" (click)="openDeleteModal(project)"></ix-icon-button>
                  </td>  
                </tr> 
          </ng-container>
      </tbody>  
    </table> 
    
    <ix-empty-state *ngIf="projects && projects.length == 0"
      header="No projects available"
      subHeader="Create a project first"
      icon="add"
      action="Create project"
      (actionClick)="addProject()"
    ></ix-empty-state>
</div>

<app-project-modals #projectModals></app-project-modals>