import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataTransferService } from './data-transfer.service';
import { FormDataService } from './form-data.service';
import { ToastService } from '@siemens/ix-angular';
import { ValidationFeedback } from 'src/const-data/validation-feedback';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private formDataService: FormDataService, private dataTransferService: DataTransferService,
    private readonly toastService: ToastService
  ) { }


  async readData(form: FormGroup) {
    
    const keysFromDialog = await this.formDataService.getAllControlsFromDialogAsArray(form);

    // Hier werden alle aktuellen Werte der entsprechenden Variablen aus dem Excel eingelesen
    const formData = await this.dataTransferService.getValuesFromExcel(keysFromDialog) as any;

    // Der Dialog wird mit den eingelesen Werten befüllt
    await this.formDataService.fillDataIntoDialog(form, formData);
  }

  async tryWriteData(form: FormGroup): Promise<boolean> {
    if (form.valid) { 
      const changedValuesToWrite = await this.formDataService.getDataFromDialog(form);
      await this.dataTransferService.writeDataToExcel(changedValuesToWrite);
      return true;
    } else {
      this.toastService.show({
        title: 'Invalid input',
        message: ValidationFeedback.invalidForm,
        type: 'error'
      });
      return false;
    }
  }
}
