<form class="form" [formGroup]="contractForm">
    <ix-typography format="h3">Contract</ix-typography>
    <ix-typography class="price-type" *ngIf="contractForm.controls['typeofpricing'].value == 0"  format="h4">GA-price</ix-typography>
    <ix-typography class="price-type" *ngIf="contractForm.controls['typeofpricing'].value == 1" format="h4">L-price</ix-typography>
    <ix-typography class="price-type" *ngIf="contractForm.controls['typeofpricing'].value == 2" format="h4">K-price</ix-typography>
    <div class="form-group">
        <label>Onboarding (one time)</label>
        <ix-input-group>
            <input type="number" formControlName="Onboarding" readonly/>
            <span slot="input-end">€</span>
          </ix-input-group>
    </div>
    <div class="form-group">
        <label>RIOpS package (yearly)</label>
        <ix-input-group>
            <input type="number" formControlName="RIOpSpackage" readonly/>
            <span slot="input-end">€</span>
          </ix-input-group>
    </div>
    <div class="form-group">
        <label>Request budget (reimbursable)</label>
        <ix-input-group>
            <input type="number" formControlName="Requestbudget" readonly/>
            <span slot="input-end">€</span>
          </ix-input-group>
    </div>
    <div class="form-group">
        <label>TOTAL PRICE</label>
        <ix-input-group>
            <input type="number" formControlName="TOTALPRICE" readonly/>
            <span slot="input-end">€</span>
          </ix-input-group>
    </div>
    <ix-typography class="disclaimer" bold="true">Disclaimer: This calculation represents a non-binding budget
        indication only. The final price may be influenced by other factors and could differ from this
        calculation.</ix-typography>
    <p></p>
    <ix-typography>This calculation is based on backend version
        {{contractForm.controls['calculationversion'].value}}.</ix-typography>
    <p></p>
    <ix-typography class="line-breaks" textColor="soft">{{contractForm.controls['servicetextprice'].value}}
    </ix-typography>
    <div class="button-container">
        <ix-button class="m-1" outline variant="primary" class="back-button" (click)="StepBack()">Back</ix-button>
    </div>
</form>
