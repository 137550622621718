import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { UIElements } from 'src/const-data/ui-elements';

interface DropDownOption {
  key: string,
  value: string
}
@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.css']
})

export class BasicInfoComponent implements OnInit {

  @Output()
  nextStep: EventEmitter<void> = new EventEmitter();

  @Input()
  basisInfoForm!: FormGroup;
  

  dropdowns = UIElements.dropDowns;

  constructor(private dataService: DataService){  
  }

  async ngOnInit(): Promise<void> {  
    await this.dataService.readData(this.basisInfoForm);
  }  

  NextStep() {
    this.nextStep.emit();
  }


}

