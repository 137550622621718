import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UIElements } from 'src/const-data/ui-elements';

interface KeyValuePair {
  [key: string]: string | number;
}

@Injectable({
  providedIn: 'root'
})
export class FormDataService {

  constructor() { }

  public async getDataFromDialog(form: FormGroup) {

    const keyValuePair: KeyValuePair = {};

    for (const control in form.controls) {

      if (!form.get(control)?.dirty || form.get(control)?.value == null) {
        continue;
      }

      if (UIElements.dropDowns[control]) {
        keyValuePair[control] = form.get(control)?.value?.value || form.get(control)?.value;
      } else {
        keyValuePair[control] = form.get(control)?.value;
      }

      form.get(control)?.markAsPristine();
    }

    return keyValuePair;
  }

  public async getAllControlsFromDialogAsArray(form: FormGroup) {
    const allControls = [];
    for (const subField in form.controls) {
      allControls.push(subField);
    }

    return allControls;
  }

  public fillDataIntoDialog(form: FormGroup, dataToWrite: any) {

    if (!dataToWrite) {
      return;
    }

    for (const subField in form.controls) {

      if (form.get(subField)) {

        if (UIElements.dropDowns[subField]) {

          try {
            let dropdownValue = UIElements.dropDowns[subField]
              .find(option => option.value.toString() === dataToWrite[subField].toString())?.value;

            form.get(subField)!.setValue(dropdownValue || undefined);
          } catch {
            form.get(subField)!.setValue(undefined);
          }

          form.get(subField)!.markAsTouched();
        } else {
          form.get(subField)!.setValue(dataToWrite[subField] || null);
        }
      }
    }
  }
}