<div *ngIf="currentProject">
    <ix-content-header slot="header" hasBackButton="true" [headerTitle]="currentProject.name" (click)="backButtonClick()">
    </ix-content-header>
    <ix-workflow-steps [selectedIndex]="stateMaschine.currentState" class="workflow" [clickable]="getFormGroup(stateMaschine.currentState).valid">
        <ix-workflow-step status="open" (click)="selectWorkflowStep(State.Step1)">Basis info</ix-workflow-step>
        <ix-workflow-step status="open" (click)="selectWorkflowStep(State.Step2)">Simple Calculation</ix-workflow-step>
        <ix-workflow-step [hidden]="isSimpleCalculation" status="open" (click)="selectWorkflowStep(State.Step3)">Inventory of Equipment</ix-workflow-step>
        <ix-workflow-step [hidden]="isSimpleCalculation" status="open" (click)="selectWorkflowStep(State.Step4)">Services to cover</ix-workflow-step>
        <ix-workflow-step status="open" (click)="selectWorkflowStep(State.Step5)">Contract</ix-workflow-step>
    </ix-workflow-steps>
    <div *ngIf="stateMaschine.currentState == State.Step1" class="container">
       <app-basic-info [basisInfoForm]="basisInfoForm" (nextStep)="NextStep()"></app-basic-info> 
    </div>
    <div *ngIf="stateMaschine.currentState == State.Step2" class="container">
        <app-simple-calculation [simpleCalculationForm]="simpleCalculationForm" (simpleCalculation)="ChangeSimpleCalculation($event)" (nextStep)="NextStep()" (stepBack)="StepBack()"></app-simple-calculation>
    </div>
    <div *ngIf="stateMaschine.currentState == State.Step3" class="container">
        <app-equipment-inventory [equipmentInventoryForm]="equipmentInventoryForm" (nextStep)="NextStep()" (stepBack)="StepBack()"></app-equipment-inventory>
    </div>
    <div *ngIf="stateMaschine.currentState == State.Step4" class="container">
        <app-services [servicesForm]="servicesForm" (nextStep)="NextStep()" (stepBack)="StepBack()"></app-services>
    </div>
    <div *ngIf="stateMaschine.currentState == State.Step5" class="container">
        <app-contract [contractForm]="contractForm" (stepBack)="StepBack()"></app-contract>    </div>

</div>
