import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { UIElements } from 'src/const-data/ui-elements';
import { ValidationFeedback } from 'src/const-data/validation-feedback';

@Component({
  selector: 'app-simple-calculation',
  templateUrl: './simple-calculation.component.html',
  styleUrls: ['./simple-calculation.component.css']
})
export class SimpleCalculationComponent {

  @Output()
  nextStep: EventEmitter<void> = new EventEmitter();
  @Output()
  stepBack: EventEmitter<void> = new EventEmitter();
  @Output()
  simpleCalculation: EventEmitter<boolean> = new EventEmitter();

  @Input()
  simpleCalculationForm!: FormGroup;

  validationFeedback = ValidationFeedback;
  dropdowns = UIElements.dropDowns;

  constructor(private dataService: DataService){  
  }

  async ngOnInit(): Promise<void> {  
    await this.dataService.readData(this.simpleCalculationForm);
  } 

  ChangeSimpleSelection() {
    const simpleCalcSelected = this.simpleCalculationForm.controls['simplecalc'].value == 'Yes'? true: false;
    this.simpleCalculation.emit(simpleCalcSelected);
  }

  NextStep() {
    this.nextStep.emit();
  }

  StepBack() {
    this.stepBack.emit();
  }


}
