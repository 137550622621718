import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { UIElements } from 'src/const-data/ui-elements';
import { ValidationFeedback } from 'src/const-data/validation-feedback';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {
  @Output()
  nextStep: EventEmitter<void> = new EventEmitter();
  @Output()
  stepBack: EventEmitter<void> = new EventEmitter();

  @Input()
  servicesForm!: FormGroup;

  validationFeedback = ValidationFeedback;
  dropdowns = UIElements.dropDowns;

  constructor(private dataService: DataService){  
  }

  async ngOnInit(): Promise<void> {  
    await this.dataService.readData(this.servicesForm);
  } 

  NextStep() {
    this.nextStep.emit();
  }

  StepBack() {
    this.stepBack.emit();
  }
}
