import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IxModule } from '@siemens/ix-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProjectCalculationComponent } from './project-calculation/project-calculation.component';
import { BasicInfoComponent } from './project-calculation/basic-info/basic-info.component';
import { UIElements } from 'src/const-data/ui-elements';
import { EquipmentInventoryComponent } from './project-calculation/equipment-inventory/equipment-inventory.component';
import { ServicesComponent } from './project-calculation/services/services.component';
import { ContractComponent } from './project-calculation/contract/contract.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';
import { AppFrameComponent } from './app-frame/app-frame.component';
import { SimpleCalculationComponent } from './project-calculation/simple-calculation/simple-calculation.component';
import { LoadingComponent } from './loading/loading.component';
import { LoadingInterceptor } from './loading/loading-interceptor';
import { MasterfileManagementComponent } from './masterfile-management/masterfile-management.component';
import { AdminProjectOverviewComponent } from './admin-project-overview/admin-project-overview.component';
import { ProjectModalsComponent } from './shared/project-modals/project-modals.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProjectCalculationComponent,
    BasicInfoComponent,
    EquipmentInventoryComponent,
    ServicesComponent,
    ContractComponent,
    AppFrameComponent,
    SimpleCalculationComponent,
    LoadingComponent,
    MasterfileManagementComponent,
    AdminProjectOverviewComponent,
    ProjectModalsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IxModule.forRoot(),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AuthModule.forRoot({
      ...env.auth,
      httpInterceptor: {
        ...env.httpInterceptor,
      },
    }),
  ],
  providers: [
    UIElements,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
