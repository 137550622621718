import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProjectCalculationComponent } from './project-calculation/project-calculation.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { MasterfileManagementComponent } from './masterfile-management/masterfile-management.component';
import { AdminGuard } from './admin.guard';
import { AdminProjectOverviewComponent } from './admin-project-overview/admin-project-overview.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'masterfile-management',
    component: MasterfileManagementComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'admin-project-overview',
    canActivate: [AuthGuard, AdminGuard],
    children: [
      {
        path: '',
        component: AdminProjectOverviewComponent,
        canActivate: [AuthGuard, AdminGuard],
      },
      {
        path: 'projects/:id',
        component: ProjectCalculationComponent,
        canActivate: [AuthGuard, AdminGuard],
      }
    ]
  },
  {
    path: 'projects/:id',
    component: ProjectCalculationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
