<form [formGroup]="simpleCalculationForm" class="form was-validated">
    <ix-typography format="h3">Simple Calculation</ix-typography>
    <div class="container">
        <div class="column">
            <div class="form-group">
                <label>Simple calculation</label>
                <br>
                <ix-select class="ix-select input-width" name="ix-select-control" formControlName="simplecalc"
                    (valueChange)="ChangeSimpleSelection()">
                    <ix-select-item *ngFor="let option of dropdowns['simplecalc']" label="{{option.value}}"
                        value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>
        </div>
        <div class="column"></div>
    </div>

    <div class="container">
        <div class="column">
            <div class="form-group" *ngIf="simpleCalculationForm.controls['simplecalc'].value == 'Yes'">
                <label>Services</label>
                <br>
                <ix-select class="ix-select input-width" name="ix-select-control" formControlName="selection">
                    <ix-select-item label="{{simpleCalculationForm.controls['servicetext1'].value}}"
                        value="{{dropdowns['selection'][0].value}}"></ix-select-item>
                    <ix-select-item label="{{simpleCalculationForm.controls['servicetext2'].value}}"
                        value="{{dropdowns['selection'][1].value}}"></ix-select-item>
                    <ix-select-item label="{{simpleCalculationForm.controls['servicetext3'].value}}"
                        value="{{dropdowns['selection'][2].value}}"></ix-select-item>
                    <ix-select-item label="{{simpleCalculationForm.controls['servicetext4'].value}}"
                        value="{{dropdowns['selection'][3].value}}"></ix-select-item>
                    <ix-select-item label="{{simpleCalculationForm.controls['servicetext5'].value}}"
                        value="{{dropdowns['selection'][4].value}}"></ix-select-item>
                    <ix-select-item label="{{simpleCalculationForm.controls['servicetext6'].value}}"
                        value="{{dropdowns['selection'][5].value}}"></ix-select-item>
                </ix-select>
            </div>
        </div>
        <div class="column">
            <div class="form-group" *ngIf="simpleCalculationForm.controls['simplecalc'].value == 'Yes'">
                <label>Description</label>
                <div class="form-group input-width" >
                    <ix-typography *ngIf="simpleCalculationForm.controls['selection'].value == 1">
                        {{simpleCalculationForm.controls['servicedescription1'].value}}
                    </ix-typography>
                    <ix-typography *ngIf="simpleCalculationForm.controls['selection'].value == 2">
                        {{simpleCalculationForm.controls['servicedescription2'].value}}
                    </ix-typography>
                    <ix-typography *ngIf="simpleCalculationForm.controls['selection'].value == 3">
                        {{simpleCalculationForm.controls['servicedescription3'].value}}
                    </ix-typography>
                    <ix-typography *ngIf="simpleCalculationForm.controls['selection'].value == 4">
                        {{simpleCalculationForm.controls['servicedescription4'].value}}
                    </ix-typography>
                    <ix-typography *ngIf="simpleCalculationForm.controls['selection'].value == 5">
                        {{simpleCalculationForm.controls['servicedescription5'].value}}
                    </ix-typography>
                    <ix-typography *ngIf="simpleCalculationForm.controls['selection'].value == 6">
                        {{simpleCalculationForm.controls['servicedescription6'].value}}
                    </ix-typography>
                </div>    
            </div>
        </div>
    </div>
    <div class="container">
        <div class="column">
            <div class="form-group input-width" *ngIf="simpleCalculationForm.controls['simplecalc'].value == 'Yes' && simpleCalculationForm.controls['selection'].value != 1">
                <label *ngIf="simpleCalculationForm.controls['selection'].value == 2">{{simpleCalculationForm.controls['amounttextlabel2'].value}}</label>
                <label *ngIf="simpleCalculationForm.controls['selection'].value == 3 || 
                            simpleCalculationForm.controls['selection'].value == 4 ||
                            simpleCalculationForm.controls['selection'].value == 5 ||
                            simpleCalculationForm.controls['selection'].value == 6">
                            {{simpleCalculationForm.controls['amounttextlabel36'].value}}</label>
                <input type="number" formControlName="hostcount" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
        </div>
        <div class="column"></div>
    </div>


    <div class="button-container">
        <ix-button class="m-1" outline variant="primary" class="back-button" (click)="StepBack()">Back</ix-button>
        <ix-button class="next-button" (click)="NextStep()">Next</ix-button>
    </div>
</form>