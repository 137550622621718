import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Project, ProjectService } from '../services/project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '@siemens/ix-angular';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription, skip, tap } from 'rxjs';
import { ProjectModalsComponent } from '../shared/project-modals/project-modals.component';

@Component({
  selector: 'app-admin-project-overview',
  templateUrl: './admin-project-overview.component.html',
  styleUrls: ['./admin-project-overview.component.css']
})
export class AdminProjectOverviewComponent {
  @ViewChild('projectModals') private projectModals!: ProjectModalsComponent;
  projects: Project[] = [];
  private subs = new Subscription();
  areProjectsLoading: boolean = false;

  constructor(protected router: Router, private activatedRoute: ActivatedRoute, private projectService: ProjectService) { }
  
  ngOnInit(): void {
    this.areProjectsLoading = true;
    this.subs.add(this.projectService.projects$
      .pipe(
        skip(1)
      )
      .subscribe((projects) => {
        this.projects = projects;
        this.areProjectsLoading = false;
      }));
    this.projectService.getProjects();
  }

  startCalculation(id: number) {   
    this.router.navigate(['./projects', id], {relativeTo: this.activatedRoute}); 
  }

  async editProject(selectedProject: Project) {
    this.projectModals.editProject(selectedProject);
  }

  async openDeleteModal(project: Project) {
    this.projectModals.openDeleteModal(project);
  } 

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
