import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormDataService } from '../services/form-data.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataTransferService } from '../services/data-transfer.service';
import { Project, ProjectService } from '../services/project.service';
import { mergeMap } from 'rxjs';
import { DataService } from '../services/data.service';
import { ToastService } from '@siemens/ix-angular';

@Component({
  selector: 'app-project-calculation',
  templateUrl: './project-calculation.component.html',
  styleUrls: ['./project-calculation.component.css']
})
export class ProjectCalculationComponent implements OnInit {

  State = State;
  protected stateMaschine = new WorkflowStateMachine();
  isSimpleCalculation: boolean = true;

  currentProject: Project | undefined;

  basisInfoForm: FormGroup = this.formBuilder.group({
    startDate: [''],
    Identifcationnumber: [''],
    Customer: [''],
    Country: [''],
    Location: [''],
    ContactRC: [''],
    TimeZone: [''],
    Contractform: [''],
    ORD: [''],
    Contractduration: [''],
    Remoteconnectionavailable: [''],
    typeofpricing: ['']
  });

  simpleCalculationForm: FormGroup = this.formBuilder.group({
    "simplecalc": [''],
    "servicetext1": [''],
    "servicetext2": [''],
    "servicetext3": [''],
    "servicetext4": [''],
    "servicetext5": [''],
    "servicetext6": [''],
    "servicedescription1": [''],
    "servicedescription2": [''],
    "servicedescription3": [''],
    "servicedescription4": [''],
    "servicedescription5": [''],
    "servicedescription6": [''],
    "amounttextlabel2": [''],
    "amounttextlabel36": [''],
    "selection": [''],
    "hostcount": [''],
    "activeselection": [''],
  });

  equipmentInventoryForm: FormGroup = this.formBuilder.group({
    Engineeringstation: [''],
    "OSServersincluding(BatchandRoutecontrol)": [''],
    OSClients: [''],
    Dataclients: [''],
    "OtherproductionrelatedVM's": [''],
    SIMITVirtualController: [''],

    "MicrosoftDefendervirus&malwaredetectie": [''],
    "TrellixEPOserver(MacAffee)": [''],
    WindowsUpdateServer: [''],
    DomainControllers: [''],
    "OtherServer(Jumphosts,etc)": [''],
    WindowsOperatingSystems: [''],

    Hosts: [''],
    vSAN: [''],
    vCenter: [''],

    ThinClientWindowsorIGELbased: [''],
    FysiekeServers: [''],
    FysiekeWorkstations: [''],
    "SIDSIBack-up&Restore": [''],
    "ProcessHistorian(PH)": [''],
    UPS: [''],
    NASascoldstorage: [''],
    Dongleserver: [''],

    FrontFirewall: [''],
    FrontFirewallredundant: [''],
    "Back-endFirewall": [''],
    "Back-endFirewallredundant": [''],
    Coreswitches: [''],
    Coreswitchesredundant: [''],
    PCNswitches: [''],
    CSNswitches: [''],
    FSNswitches: [''],
  });

  servicesForm: FormGroup = this.formBuilder.group({
    ContinuousMonitoring: [''],
    IncidentManagement: [''],
    DisasterRecoverySupport: [''],
    Reporting: [''],
    NetworkManagement: [''],
    DomainManagement: [''],

    "WindowsServerUpdateServices(WSUS)": [''],
    "WindowsServerUpdateServices(WSUS)Frq": [''],
    "Back-up&Restore": [''],
    "Backup&restoreVM'sFrq": [''],
    "Software&FirmwareUpdates": [''],
    "Firmwareupdates": [''],
    PreventiveMaintenance: [''],
    "Preventive/periodicmaintenanceFrq": [''],  
    "Configuration&ChangeManagement": [''],
    AssetManagement: [''],   

    Securitypatching: [''],
    TrafficMonitoring: [''],
    VulnerabilityManagement: [''],
    AnomalyDetectionText: [''],
    AnomalyDetection: [''],
    AnomalyDetectionbuttonhide: [''],
    SIEMText: [''],
    "SecurityInformation&EventManagement(SIEM)": [''],
    SIEMTextbuttonhide: [''],
    CertText: [''],
    "CyberEmergencyResponseTeam(CERT)": [''],
    CertTextbuttonhide: [''],

    RemoteSupport: [''],
    ServiceDesk: [''],
    ServiceRequestManagement: [''],
    ServiceRequestManagementhours: [''],
  });

  contractForm: FormGroup = this.formBuilder.group({
    Onboarding: [''],
    RIOpSpackage: [''],
    Requestbudget: [''],
    TOTALPRICE: [''],
    calculationversion: [''],
    servicetextprice: [''],
    typeofpricing: ['']
  });

  constructor(protected router: Router, private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private formDataService: FormDataService,
    private dataTransferService: DataTransferService, private projectService: ProjectService,
    private dataService: DataService, private readonly toastService: ToastService) { }

  async ngOnInit() {
    this.activatedRoute.params.pipe(
      mergeMap((params) => this.projectService.getProject(+params['id'])))
      .subscribe({ 
        next: async (project) => {          
          sessionStorage.setItem("SelectedProjectId", project.id.toString());
          await this.getIsSimpleCalc();
          this.currentProject = project;
        },
        error: () => {
          this.toastService.show({ 
            title: 'Load data error',
            message: 'We cannot load the data of the project. ', 
            type: "error" 
          });
          this.currentProject = undefined;
          sessionStorage.setItem("SelectedProjectId", "");
          this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
        }}); 
  }

  async getIsSimpleCalc() {
    const formData = await this.dataTransferService.getValuesFromExcel(["simplecalc"]) as any;
    this.isSimpleCalculation = formData["simplecalc"].toString() == "Yes"? true : false;
  }

  ChangeSimpleCalculation(isSimpleCalculation: boolean) {
    this.isSimpleCalculation = isSimpleCalculation;
  }

  async selectWorkflowStep(state: State) {
    let formCurrentState: FormGroup = this.getFormGroup(this.stateMaschine.currentState);
    const isWritingSuccessful = await this.dataService.tryWriteData(formCurrentState);
    if (isWritingSuccessful) {
      this.stateMaschine.setState(state);
    }    
  }

  getFormGroup(currentState: State): FormGroup {
    switch (currentState) {
      case State.Step1:
        return this.basisInfoForm;
      case State.Step2:
        return this.simpleCalculationForm;
      case State.Step3:
        return this.equipmentInventoryForm;
      case State.Step4:
        return this.servicesForm;
      case State.Step5:
        return this.contractForm;
    }
  }

  async NextStep() {
    let formCurrentState: FormGroup = this.getFormGroup(this.stateMaschine.currentState);
    const isWritingSuccessful = await this.dataService.tryWriteData(formCurrentState);
    if (isWritingSuccessful) {
      this.stateMaschine.forward(this.isSimpleCalculation);
    }
  }

  async StepBack() {
    let formCurrentState: FormGroup = this.getFormGroup(this.stateMaschine.currentState);
    const isWritingSuccessful = await this.dataService.tryWriteData(formCurrentState);
    if (isWritingSuccessful) {
      this.stateMaschine.back(this.isSimpleCalculation);
    }
  }

  backButtonClick() {
    this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
  }
}

export enum State {
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
}

interface WorkflowStep {
  from: State;
  to: State;
}

const advancedCalcTransitions: WorkflowStep[] = [
  { from: State.Step1, to: State.Step2 },
  { from: State.Step2, to: State.Step3 },
  { from: State.Step3, to: State.Step4 },
  { from: State.Step4, to: State.Step5 },
];

const simpleCalcTransitions: WorkflowStep[] = [
  { from: State.Step1, to: State.Step2 },
  { from: State.Step2, to: State.Step5 },
];

class WorkflowStateMachine {

  currentState: State = State.Step1;

  public forward(isSimpleCalculation: boolean): void {
    const currentStateIndex = this.currentState;
    const transitions = isSimpleCalculation? simpleCalcTransitions : advancedCalcTransitions;
    const transition = transitions.find(
      (t) => t.from === currentStateIndex
    );

    if (transition) {
      this.currentState = transition.to;
    } else {
      throw new Error("Invalid transition");
    }
  }


  public back(isSimpleCalculation: boolean): void {
    const currentStateIndex = this.currentState;
    const transitions = isSimpleCalculation? simpleCalcTransitions : advancedCalcTransitions;
    const transition = transitions.find(
      (t) => t.to === currentStateIndex
    );

    if (transition) {
      this.currentState = transition.from;
    } else {
      throw new Error("Invalid transition");
    }
  }

  public setState(state: State): void {
    this.currentState = state;
  }
}
