import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTransferService } from '../services/data-transfer.service';
import { Project, ProjectService } from '../services/project.service';
import { Subscription, map, skip } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { ProjectModalsComponent } from '../shared/project-modals/project-modals.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  @ViewChild('projectModals') private projectModals!: ProjectModalsComponent;
  projects: Project[] = [];
  private projectSubscription = new Subscription();
  areProjectsLoading: boolean = false;

  user = this.userService.createDefaultUser();
  private userSubscription = new Subscription();
  

  constructor(protected router: Router, private projectService: ProjectService, private userService: UserService) { 
  }
  
  ngOnInit(): void {
    this.areProjectsLoading = true;
    this.userSubscription.add(
      this.userService.user$.subscribe((user) => this.user = user));

    this.projectSubscription.add(
      this.projectService.projects$.pipe(
        skip(1),
        map(projects => projects.filter(project => project.owner == this.user.email))
      ).subscribe((projects) => {
        this.projects = projects;
        this.areProjectsLoading = false;
      }));

    this.projectService.getProjects();
  }

   async addProject() {
    this.projectModals.addProject();
  }

  async editProject(selectedProject: Project) {
    this.projectModals.editProject(selectedProject);
  }

  async openDeleteModal(project: Project) {
    this.projectModals.openDeleteModal(project);
  } 

  startCalculation(id: number) {
    
    this.router.navigate(['/projects', id]); 
  }


  ngOnDestroy(): void {
    this.projectSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }
}
