import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { UIElements } from 'src/const-data/ui-elements';
import { ValidationFeedback } from 'src/const-data/validation-feedback';

@Component({
  selector: 'app-equipment-inventory',
  templateUrl: './equipment-inventory.component.html',
  styleUrls: ['./equipment-inventory.component.css']
})
export class EquipmentInventoryComponent implements OnInit {
  @Output()
  nextStep: EventEmitter<void> = new EventEmitter();
  @Output()
  stepBack: EventEmitter<void> = new EventEmitter();

  @Input()
  equipmentInventoryForm!: FormGroup;
  
  validationFeedback = ValidationFeedback;
  dropdowns = UIElements.dropDowns;
  
  constructor(private dataService: DataService){  
  }

  async ngOnInit(): Promise<void> {  
    await this.dataService.readData(this.equipmentInventoryForm);
  } 

  NextStep() {
    this.nextStep.emit();
  }

  StepBack() {
    this.stepBack.emit();
  }
}
