import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, mergeMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToastService } from '@siemens/ix-angular';

export interface Project {
  id: number;
  name: string;
  owner: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  private projectsSubject = new BehaviorSubject<Project[]>([]);
  projects$ = this.projectsSubject.asObservable();

  constructor(private readonly http: HttpClient, private readonly toastService: ToastService) { }

  public getProjects(): void {
    this.http.get<Project[]>(`${environment.baseUrl}/projects`)
      .subscribe({
        next: (projects) => {
          this.projectsSubject.next(projects);
        },
        error: (err) => {
          const details = err.message || "";
          this.toastService.show({ 
            title: 'Projects error',
            message: 'We cannot load the projects from the server. ' + details, 
            type: "error" 
          });
        }
      });
  }

  public getProject(id: number) {
    return this.http.get<Project>(`${environment.baseUrl}/projects/${id}`);
  }

  public createProject(project = {}) {
    this.http.post<Project>(`${environment.baseUrl}/calculation-file/create`, project)
      .subscribe({
        next: (projectFromDatabase) => {
            const currentProject = this.projectsSubject.getValue();
            const updatedProjects = [...currentProject, projectFromDatabase]
            this.projectsSubject.next(updatedProjects);
        },
        error: (err) => {
          const details = err.message || "";
          this.toastService.show({ 
            title: 'Create project error',
            message: 'We cannot create the project. Try again. ' + details, 
            type: "error" 
          });
        }
      });
  }

  public deleteProject(id: number): void {
    this.http.delete<void>(`${environment.baseUrl}/projects/${id}`)
      .subscribe({
        next: () => {
          const currentProjects = this.projectsSubject.getValue();
          currentProjects.forEach((item, index) => {
            if (item.id === id) { currentProjects.splice(index, 1); }
          });
          this.projectsSubject.next(currentProjects);
        },
        error: (err) => {
          const details = err.message || "";
          this.toastService.show({ 
            title: 'Delete project error',
            message: 'We cannot delete the project. Try again. ' + details, 
            type: "error" 
          });
        }
      });
  }

  public updateProject(id: number, updatedProject = {}) {
    this.http.patch<Project>(`${environment.baseUrl}/projects/${id}`, updatedProject)
      .subscribe({
        next: (project) => {
          const currentProjects = this.projectsSubject.getValue();
          currentProjects.forEach((item, index) => {
            if (item.id === id) { currentProjects[index] = project; }
          });
          this.projectsSubject.next(currentProjects);
        },
        error: (err) => {
          const details = err.message || "";
          this.toastService.show({ 
            title: 'Update project error',
            message: 'We cannot update the project. Try again. ' + details, 
            type: "error" 
          });
        }
      });
  }
}
