<form [formGroup]="basisInfoForm" class="form">
    <ix-typography format="h3">Basis info</ix-typography>
    <div class="form-group">
        <label>Start Date</label>
        <input type="text" placeholder="dd.MM.yyyy" formControlName="startDate">
    </div>
    <div class="form-group">
        <label>Identifcation number</label>
        <input type="text" formControlName="Identifcationnumber">
    </div>
    <div class="form-group">
        <label>Customer</label>
        <input type="text" formControlName="Customer">
    </div>
    <div class="form-group">
        <label>Country</label>
        <input type="text" formControlName="Country">
    </div>
    <div class="form-group">
        <label>Location</label>
        <textarea formControlName="Location"></textarea>
    </div>
    <div class="form-group">
        <label>Contact RC</label>
        <textarea formControlName="ContactRC"></textarea>
    </div>
    <div class="form-group">
        <label>Time Zone</label>
        <input type="text" formControlName="TimeZone">
    </div>
    <div class="form-group">
        <label>Contract form</label>
        <br>  
        <ix-select class="ix-select" name="ix-select-control" formControlName="Contractform">
            <ix-select-item *ngFor="let option of dropdowns['Contractform']" label="{{option.value}}" value="{{option.value}}">
            </ix-select-item>
        </ix-select>
    </div>
    <div class="form-group">
        <label>ORD</label>
        <input type="text" formControlName="ORD">
    </div>
    <div class="form-group">
        <label>Contract duration</label>
        <ix-input-group>
            <input type="number" formControlName="Contractduration" readonly/>
            <span slot="input-end">year(s)</span>
          </ix-input-group>
    </div>
    <div class="form-group">
        <label>Remote connection available</label>
        <br>  
        <ix-select class="ix-select" name="ix-select-control" formControlName="Remoteconnectionavailable">
            <ix-select-item *ngFor="let option of dropdowns['Remoteconnectionavailable']" label="{{option.value}}" value="{{option.value}}">
            </ix-select-item>
        </ix-select>
    </div>
    <div class="form-group">
        <label>Type of pricing</label>
        <br>
        <ix-select class="ix-select" name="ix-select-control" formControlName="typeofpricing">
            <ix-select-item label="GA-price"
                value="{{dropdowns['typeofpricing'][0].value}}"></ix-select-item>
            <ix-select-item label="L-price"
                value="{{dropdowns['typeofpricing'][1].value}}"></ix-select-item>
            <ix-select-item label="K-price"
                value="{{dropdowns['typeofpricing'][2].value}}"></ix-select-item>
        </ix-select>
    </div>
    
    


    <div class="button-container">
        <ix-button class="next-button" (click)="NextStep()">Next</ix-button>
    </div>
</form>
