import { Component } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { UserService } from '../services/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-app-frame',
  templateUrl: './app-frame.component.html',
  styleUrls: ['./app-frame.component.css']
})
export class AppFrameComponent {

  user = this.userService.createDefaultUser();
  userSubscription: Subscription | undefined;
  
  constructor(protected authService: AuthService, protected userService: UserService) {
    this.userSubscription = this.userService.user$.subscribe(
      (user) => {
        this.user = user
      }
    );
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }
}
