import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@siemens/ix-angular';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FileTransferService {

  private saveAs(blob: Blob, fileName: string) {
    const downloadUrl = URL.createObjectURL(blob);

    let link = document.createElement('a');
    link.href = downloadUrl;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(downloadUrl);
    document.body.removeChild(link);
  }

  constructor(private httpService: HttpClient, private toastService: ToastService) { }

  
  async downloadFile(fileType: string){

    let endpoint = '';
    if(fileType == 'Masterfile'){
      endpoint = environment.baseUrl +  '/calculation-file/masterfile/download';
    } else {
      // andere filetypen implementieren, z.B. project etc. Bis dahin return
      return;
    }

    const options = {
      headers: new HttpHeaders({
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }),
      responseType: 'blob' as const,
      observe: 'body' as const
    };

    this.httpService.get(endpoint, options).subscribe(blob => {
      this.saveAs(blob, fileType + '.xlsx');

    }, (errorResponse: HttpErrorResponse) => {

      const details = errorResponse.message || "";
      this.toastService.show({ 
        title: 'Download error',
        message: 'We cannot download the file from the server. Try again. ' + details, 
        type: "error" 
      });

    });

  }

  async uploadFile(fileType: string, file: File, id?: string){

    return new Promise((resolve, reject) => {

      let endpoint = '';

      if(fileType == 'Masterfile'){
        endpoint = environment.baseUrl + '/calculation-file/masterfile/upload';
      } else if (fileType == 'Project'){
        endpoint = environment.baseUrl + `/calculation-file/masterfile/upload/${id}`;

      }
      const formData: FormData = new FormData();
      formData.append('file', file);

      const options = {
        observe: 'response' as const
      };

      this.httpService.post(endpoint, formData, options).subscribe(response => {

        return resolve(true);

      }, (errorResponse: HttpErrorResponse) => {

        const details = errorResponse.message || "";
        this.toastService.show({ 
          title: 'Upload error',
          message: 'We cannot upload the file to the server. Try again. ' + details, 
          type: "error" 
        });


        return reject();
      });
    });

  }
}
