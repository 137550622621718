import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastService } from '@siemens/ix-angular';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DataTransferService {

  constructor(protected router: Router, private httpClient: HttpClient, private readonly toastService: ToastService) { }

  public writeDataToExcel(dataToWrite: any) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const projectId = sessionStorage.getItem('SelectedProjectId')?.toString() || '';

        if (typeof dataToWrite === 'string') {
          dataToWrite = JSON.parse(dataToWrite);
        }

        if (Object.keys(dataToWrite).length === 0) {
          return resolve();
        }

        const body = dataToWrite;
        const options = {
          observe: 'response' as const,
          responseType: 'text' as const
        };
        const url = environment.baseUrl + `/calculation-file/write/${projectId}`;
        this.httpClient.post(url, body, options)
          .subscribe({
            next: () => {
              return resolve();
            },
            error: (err) => {
              const details = err.message || "";
              this.toastService.show({ 
                title: 'Update error',
                message: 'We cannot update the values of your calculation. ' + details, 
                type: "error" 
              });
              return reject();
            }
          });
      } catch (err: any) {
        const details = err.message || "";
        this.toastService.show({ 
          title: 'Update error',
          message: 'We cannot update the values of your calculation. ' + details, 
          type: "error" 
        });
        return reject();
      } 
    });
  }

  public getValuesFromExcel(keys: string[]) {
    return new Promise(async (resolve, reject) => {
      const projectId = sessionStorage.getItem('SelectedProjectId')?.toString() || '';
      if (projectId === '') {
        this.toastService.show({ 
          title: 'No project selected',
          message: 'You need to open a project first. ', 
          type: "error" 
        });
        const parentUrl = this.router.url.substring(0, this.router.url.indexOf("projects/"));
        this.router.navigate([parentUrl]);
        return reject();
      } 
      const url = environment.baseUrl + `/calculation-file/read/${projectId}`;
      const body = keys;
      const options = {
        observe: 'response' as const
      };

      this.httpClient.post(url, body, options)
        .subscribe({
          next: (response) => {
            return resolve(response.body);
          },
          error: (error) => {
            const details = error.message || "";
            this.toastService.show({ 
              title: 'Load data error',
              message: 'We cannot load the data. ' + details, 
              type: "error" 
            });

            sessionStorage.setItem("SelectedProjectId", "");
            const parentUrl = this.router.url.substring(0, this.router.url.indexOf("projects/"));
            this.router.navigate([parentUrl]);

            return reject();
          }
        });

    });
  }

  public getProjectFile(id: string) {
    let url = environment.baseUrl + `/calculation-file/download/${id}`;
    const options = {
      responseType: 'blob' as const
    }

    this.httpClient.get(url, options)
      .subscribe({
        next: (response: Blob) => {
          this.saveAs(response, `${id}.xlsx`);
        },
        error: (error) => {
          const details = error.message || "";
          this.toastService.show({ 
            title: 'Download error',
            message: 'We cannot download the file from the server. Try again. ' + details, 
            type: "error" 
          });
          return false;
        }
      });
  }

  private saveAs(blob: Blob, fileName: string) {
    const downloadUrl = URL.createObjectURL(blob);

    let link = document.createElement('a');
    link.href = downloadUrl;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(downloadUrl);
    document.body.removeChild(link);
  }
}
