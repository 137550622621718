<form [formGroup]="servicesForm" class="form was-validated">
    <ix-typography format="h3">Step 2: Services to cover</ix-typography>
    <div class="container">
        <div class="column">
            <ix-typography format="h4">Available</ix-typography>
            <div class="form-group">
                <label>Continuous Monitoring</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="ContinuousMonitoring">
                    <ix-select-item *ngFor="let option of dropdowns['ContinuousMonitoring']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>
            <div class="form-group">
                <label>Incident Management</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="IncidentManagement">
                    <ix-select-item *ngFor="let option of dropdowns['IncidentManagement']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>
            <div class="form-group">
                <label>Disaster Recovery Support</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="DisasterRecoverySupport">
                    <ix-select-item *ngFor="let option of dropdowns['DisasterRecoverySupport']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>
            <div class="form-group">
                <label>Reporting</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="Reporting">
                    <ix-select-item *ngFor="let option of dropdowns['Reporting']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>
            <div class="form-group">
                <label>Network Management</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="NetworkManagement">
                    <ix-select-item *ngFor="let option of dropdowns['NetworkManagement']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>
            <div class="form-group">
                <label>Domain Management</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="DomainManagement">
                    <ix-select-item *ngFor="let option of dropdowns['DomainManagement']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>
        </div>

        <div class="column">
            <ix-typography format="h4">Maintained</ix-typography>
            <div class="form-group">
                <label>Windows Server Update Services (WSUS)</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="WindowsServerUpdateServices(WSUS)">
                    <ix-select-item *ngFor="let option of dropdowns['WindowsServerUpdateServices(WSUS)']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>
            <div class="form-group" *ngIf="servicesForm.controls['WindowsServerUpdateServices(WSUS)'].value == 'Yes'">
                <label>Windows Server Update Services (WSUS) - Frequency</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="WindowsServerUpdateServices(WSUS)Frq">
                    <ix-select-item *ngFor="let option of dropdowns['WindowsServerUpdateServices(WSUS)Frq']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>
            <div class="form-group">
                <label>Back-up & Restore VM's</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="Back-up&Restore">
                    <ix-select-item *ngFor="let option of dropdowns['Back-up&Restore']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>
            <div class="form-group" *ngIf="servicesForm.controls['Back-up&Restore'].value == 'Yes'">
                <label>Backup & restore VM's - Frequency</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="Backup&restoreVM'sFrq">
                    <ix-select-item *ngFor="let option of dropdowns['Backup&restoreVM\'sFrq']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>
            <div class="form-group">
                <label>Software & Firmware Updates</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="Software&FirmwareUpdates">
                    <ix-select-item *ngFor="let option of dropdowns['Software&FirmwareUpdates']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>
            <div class="form-group" *ngIf="servicesForm.controls['Software&FirmwareUpdates'].value == 'Yes'">
                <label>Software & Firmware Updates - Frequency</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="Firmwareupdates">
                    <ix-select-item *ngFor="let option of dropdowns['Firmwareupdates']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>
            <div class="form-group">
                <label>Preventive / Periodic Maintenance</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="PreventiveMaintenance">
                    <ix-select-item *ngFor="let option of dropdowns['PreventiveMaintenance']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>
            <div class="form-group" *ngIf="servicesForm.controls['PreventiveMaintenance'].value == 'Yes'">
                <label>Preventive / Periodic Maintenance - Frequency</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="Preventive/periodicmaintenanceFrq">
                    <ix-select-item *ngFor="let option of dropdowns['Preventive/periodicmaintenanceFrq']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>
            <div class="form-group">
                <label>Configuration & ChangeManagement</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="Configuration&ChangeManagement">
                    <ix-select-item *ngFor="let option of dropdowns['Configuration&ChangeManagement']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>
            <div class="form-group">
                <label>Asset Management</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="AssetManagement">
                    <ix-select-item *ngFor="let option of dropdowns['AssetManagement']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>          
        </div>
    </div>

    <div class="container">
        <div class="column">
            <ix-typography format="h4">Security</ix-typography>
            <div class="form-group">
                <label>Security patching</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="Securitypatching">
                    <ix-select-item *ngFor="let option of dropdowns['Securitypatching']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>    
            <div class="form-group">
                <label>Traffic Monitoring</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="TrafficMonitoring">
                    <ix-select-item *ngFor="let option of dropdowns['TrafficMonitoring']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>  
            <div class="form-group">
                <label>Vulnerability Management</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="VulnerabilityManagement">
                    <ix-select-item *ngFor="let option of dropdowns['VulnerabilityManagement']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>  
            <div class="form-group">
                <label>{{servicesForm.controls['AnomalyDetectionText'].value}}</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="AnomalyDetection" *ngIf="servicesForm.controls['AnomalyDetectionbuttonhide'].value != 'true'">
                    <ix-select-item *ngFor="let option of dropdowns['AnomalyDetection']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>  
            <div class="form-group">
                <label>{{servicesForm.controls['SIEMText'].value}}</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="SecurityInformation&EventManagement(SIEM)" *ngIf="servicesForm.controls['SIEMTextbuttonhide'].value != 'true'">
                    <ix-select-item *ngFor="let option of dropdowns['SecurityInformation&EventManagement(SIEM)']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>  
            <div class="form-group">
                <label>{{servicesForm.controls['CertText'].value}}</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="CyberEmergencyResponseTeam(CERT)" *ngIf="servicesForm.controls['CertTextbuttonhide'].value != 'true'">
                    <ix-select-item *ngFor="let option of dropdowns['CyberEmergencyResponseTeam(CERT)']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>   
        </div>
        <div class="column">
            <ix-typography format="h4">Supported</ix-typography>
            <div class="form-group">
                <label>Remote Support</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="RemoteSupport">
                    <ix-select-item *ngFor="let option of dropdowns['RemoteSupport']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>     
            <div class="form-group">
                <label>Service Desk</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="ServiceDesk">
                    <ix-select-item *ngFor="let option of dropdowns['ServiceDesk']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>     
            <div class="form-group">
                <label>Service Request</label>
                <br>  
                <ix-select class="ix-select" name="ix-select-control" formControlName="ServiceRequestManagement">
                    <ix-select-item *ngFor="let option of dropdowns['ServiceRequestManagement']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </div>     
            <div class="form-group">
                <label>Service Request (hours)</label>
                <ix-input-group>
                    <input type="number" formControlName="ServiceRequestManagementhours" min="0" step="1">
                    <span slot="input-end">hours covered</span>
                    <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                  </ix-input-group>           
            </div> 
        </div>
    </div>

    <div class="button-container">
        <ix-button class="m-1" outline variant="primary" class="back-button" (click)="StepBack()">Back</ix-button>
        <ix-button class="next-button" (click)="NextStep()">Next</ix-button>
    </div>
</form>