<ix-application forceBreakpoint="md">
  <ix-application-header name="RIOpS Calculator">
    <div class="placeholder-logo" slot="logo"></div>
    <ix-avatar [username]="user.fullName" [initials]="user.initials"
      [image]="user.picture" [extra]="user.isAdmin? 'Administrator': 'User'">
      <ix-dropdown-item label="Logout" icon="log-out" (click)="logout()"></ix-dropdown-item>
    </ix-avatar>
  </ix-application-header>

  <ix-menu>
    <ix-menu-item home-tab icon="home" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/">Home</ix-menu-item>
    <ix-menu-item *ngIf="user.isAdmin" routerLinkActive="active" home-tab icon="document" routerLink="/masterfile-management">Masterfile</ix-menu-item>
    <ix-menu-item *ngIf="user.isAdmin" routerLinkActive="active" home-tab icon="monitor" routerLink="/admin-project-overview">Admin project overview</ix-menu-item>
  </ix-menu>

  <ix-content>
    <app-loading-indicator/>
    <router-outlet></router-outlet>
  </ix-content>
</ix-application>