import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalService } from '@siemens/ix-angular';
import { Project, ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-project-modals',
  templateUrl: './project-modals.component.html',
  styleUrls: ['./project-modals.component.css']
})
export class ProjectModalsComponent {
  @ViewChild('addProjectModal', { read: TemplateRef }) addProjectModalRef!: TemplateRef<any>;
  @ViewChild('editProjectModal', { read: TemplateRef }) editProjectModalRef!: TemplateRef<any>;
  @ViewChild('deleteProjectModal', { read: TemplateRef }) deleteProjectModalRef!: TemplateRef<any>;

  projectForm = this.formBuilder.group({
    name: ['', Validators.required]
  })

  constructor(protected router: Router, private projectService: ProjectService,
    private readonly modalService: ModalService, private formBuilder: FormBuilder) {
  }

  async addProject() {
    const instance = await this.modalService.open({
      content: this.addProjectModalRef,
      centered: true
    });

    instance.onDismiss.on(() => {
      this.projectForm.reset();
    });

    instance.onClose.on(() => {
      this.projectService.createProject(this.projectForm.value);
      this.projectForm.reset();
    });
  }

  async editProject(selectedProject: Project) {
    if (selectedProject) {
      this.projectForm.setValue({ name: selectedProject.name });
      const instance = await this.modalService.open({
        content: this.editProjectModalRef,
        centered: true
      });

      instance.onDismiss.on(() => {
        this.projectForm.reset();
      });

      instance.onClose.on(() => {
        this.projectService.updateProject(selectedProject.id, this.projectForm.value);
        this.projectForm.reset();
      });
    }
  }

  async openDeleteModal(project: Project) {
    const instance = await this.modalService.open({
      content: this.deleteProjectModalRef,
      data: project.name,
      centered: true
    });

    instance.onClose.on(() => {
      this.projectService.deleteProject(project.id);
    });
  }
}
