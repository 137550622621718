<form [formGroup]="equipmentInventoryForm" class="form was-validated">
    <ix-typography format="h3">Step 1: Inventory of Equipment</ix-typography>
    <div class="container">
        <div class="column">
            <ix-typography format="h4">Application</ix-typography>
            <div class="form-group">
                <label>Engineering station</label>
                <input type="number" formControlName="Engineeringstation" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
            <div class="form-group">
                <label>OS Servers including (Batch and Route control)</label>
                <input type="number" formControlName="OSServersincluding(BatchandRoutecontrol)" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
            <div class="form-group">
                <label>OS Clients</label>
                <input type="number" formControlName="OSClients" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
            <div class="form-group">
                <label>Data Clients</label>
                <input type="number" formControlName="Dataclients" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
            <div class="form-group">
                <label>Other production related VM's</label>
                <input type="number" formControlName="OtherproductionrelatedVM's" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
            <div class="form-group">
                <label>SIMIT Virtual Controller</label>
                <input type="number" formControlName="SIMITVirtualController" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
        </div>

        <div class="column">
            <ix-typography format="h4">OS</ix-typography>
            <div class="form-group">
                <label>Microsoft Defender virus & malware detection</label>
                <input type="number" formControlName="MicrosoftDefendervirus&malwaredetectie" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
            <div class="form-group">
                <label>Trellix EPO server (MacAffee)</label>
                <input type="number" formControlName="TrellixEPOserver(MacAffee)" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
            <div class="form-group">
                <label>Windows Update Server</label>
                <input type="number" formControlName="WindowsUpdateServer" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
            <div class="form-group">
                <label>Domain Controllers</label>
                <input type="number" formControlName="DomainControllers" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
            <div class="form-group">
                <label>Other Server (Jumphosts, etc)</label>
                <input type="number" formControlName="OtherServer(Jumphosts,etc)" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
            <div class="form-group">
                <label>Windows Operating Systems</label>
                <input type="number" formControlName="WindowsOperatingSystems" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="column">
            <ix-typography format="h4">Virtualisation</ix-typography>
            <div class="form-group">
                <label>Hosts</label>
                <input type="number" formControlName="Hosts" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
            <div class="form-group">
                <label>vSAN</label>
                <input type="number" formControlName="vSAN" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
            <div class="form-group">
                <label>vCenter</label>
                <input type="number" formControlName="vCenter" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
        </div>
        <div class="column"></div>
    </div>


    <div class="container">
        <div class="column">
            <ix-typography format="h4">Hardware</ix-typography>
            <div class="form-group">
                <label>Thin Client Windows or IGEL based</label>
                <input type="number" formControlName="ThinClientWindowsorIGELbased" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
            <div class="form-group">
                <label>Fysieke Servers</label>
                <input type="number" formControlName="FysiekeServers" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
            <div class="form-group">
                <label>Fysieke Workstations</label>
                <input type="number" formControlName="FysiekeWorkstations" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
            <div class="form-group">
                <label>SIDSI Back-up & Restore</label>
                <input type="number" formControlName="SIDSIBack-up&Restore" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
            <div class="form-group">
                <label>Process Historian (PH)</label>
                <input type="number" formControlName="ProcessHistorian(PH)" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
            <div class="form-group">
                <label>UPS</label>
                <input type="number" formControlName="UPS" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
            <div class="form-group">
                <label>NAS as cold storage</label>
                <input type="number" formControlName="NASascoldstorage" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
            <div class="form-group">
                <label>Dongle server</label>
                <input type="number" formControlName="Dongleserver" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
        </div>
        <div class="column">
            <ix-typography format="h4">Network</ix-typography>
            <div class="form-group input-container">
                <div>
                    <label>Front Firewall</label>
                    <input type="number" formControlName="FrontFirewall" min="0" step="1">
                    <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                </div>
                <div>
                    <label>Front Firewall redundant</label>
                    <br>  
                    <ix-select class="ix-select" name="ix-select-control" formControlName="FrontFirewallredundant">
                        <ix-select-item *ngFor="let option of dropdowns['FrontFirewallredundant']" label="{{option.value}}" value="{{option.value}}">
                        </ix-select-item>
                    </ix-select>
                </div>
            </div>
            <div class="form-group input-container">
                <div>
                    <label>Back-end Firewall</label>
                    <input type="number" formControlName="Back-endFirewall" min="0" step="1">
                    <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                </div>
                <div>
                    <label>Back-end Firewall redundant</label>
                    <br>
                    <ix-select class="ix-select" name="ix-select-control" formControlName="Back-endFirewallredundant">
                        <ix-select-item *ngFor="let option of dropdowns['Back-endFirewallredundant']"
                            label="{{option.value}}" value="{{option.value}}">
                        </ix-select-item>
                    </ix-select>
                </div>
            </div>
            <div class="form-group input-container">
                <div>
                    <label>Core switches</label>
                    <input type="number" formControlName="Coreswitches" min="0" step="1">
                    <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                </div>
                <div>
                    <label>Core switches redundant</label>
                    <br>
                    <ix-select class="ix-select" name="ix-select-control" formControlName="Coreswitchesredundant">
                        <ix-select-item *ngFor="let option of dropdowns['Coreswitchesredundant']" label="{{option.value}}"
                            value="{{option.value}}">
                        </ix-select-item>
                    </ix-select>
                </div>
            </div>
            <div class="form-group">
                <label>PCN switches</label>
                <input type="number" formControlName="PCNswitches" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
            <div class="form-group">
                <label>CSN switches</label>
                <input type="number" formControlName="CSNswitches" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
            <div class="form-group">
                <label>FSN switches</label>
                <input type="number" formControlName="FSNswitches" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </div>
        </div>
    </div>
    <div class="button-container">
        <ix-button class="m-1" outline variant="primary" class="back-button" (click)="StepBack()">Back</ix-button>
        <ix-button class="next-button" (click)="NextStep()">Next</ix-button>
    </div>
</form>